<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Iron oxide,
        <chemical-latex content="Fe2O3" />
        , can be reduced back to iron metal,
        <chemical-latex content="Fe" />
        , through a reaction with carbon.
      </p>

      <p class="mb-4 pl-6">
        <chemical-latex content="Fe2O3 + 3 C -> 2 Fe + 3 CO" />
      </p>

      <p class="mb-3">
        Assuming that a reaction involving
        <number-value :value="molesRust" />
        moles of
        <chemical-latex content="Fe2O3" />
        and an excess of carbon, produced
        <number-value :value="massIron" />
        g of
        <chemical-latex content="Fe" />
        , answer each of the following questions:
      </p>

      <p class="mb-2">
        a) What is the actual amount of
        <chemical-latex content="Fe" />
        produced?
      </p>

      <calculation-input
        v-model="inputs.actualMolesFe"
        prepend-text="$\text{Fe:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        b) What is the theoretical yield of
        <chemical-latex content="Fe" />
        (in mol)?
      </p>

      <calculation-input
        v-model="inputs.theoreticalMolesFe"
        prepend-text="$\text{Fe:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">c) What is the percent yield for the reaction?</p>

      <calculation-input
        v-model="inputs.percentYield"
        prepend-text="$\text{Yield:}$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ResponseFeedback from '../response-utils/ResponseFeedback';
import TaskButtons from '../TaskButtons';
import SubmitButton from '../response-utils/SubmitButton';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question70',
  components: {
    ChemicalLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        actualMolesFe: null,
        theoreticalMolesFe: null,
        percentYield: null,
      },
    };
  },
  computed: {
    molesRust() {
      return this.taskState.getValueBySymbol('molesRust').content;
    },
    massIron() {
      return this.taskState.getValueBySymbol('massIron').content;
    },
  },
};
</script>
